.rf-modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.rf-modal-content {
  padding: 20px;
}

.rf-modal-header, 
.rf-modal-footer {
  border: none;
}
.rf-modal-footer {
  justify-content: flex-start;
}