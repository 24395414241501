.custom-alert {
    background-color: var(--clr-pure-white);
    /* TODO: Magic number here, fix this */
    padding: 12px;
    border-radius: 6px;

    font-weight: 500;
    font-size: 15px;
    /* INFO: This is a hack */
    max-width: fit-content;
    min-width: 50%;
}

.custom-alert-full-width{
    width: 100%;
    max-width: unset;
}

.custom-alert-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-alert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-alert__success {
    border: 1px solid var(--clr-info-success-default);
}
.custom-alert__success .custom-alert-icon {
    color: var(--clr-info-success-default);
}

.custom-alert__danger {
    border: 1px solid var(--clr-info-error-default);
}
.custom-alert__danger .custom-alert-icon {
    color: var(--clr-info-error-default);
}

.custom-alert__warning {
    border: 1px solid var(--clr-info-alert-default);
}
.custom-alert__warning .custom-alert-icon {
    color: var(--clr-info-alert-default);
}
