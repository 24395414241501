.enterprise-title {
  font-size: 20px;
}

.enterprise-page-subtitle {
  font-size: 16px;
  font-weight: 700;
}

.text-thin {
  font-weight: 300;
}
