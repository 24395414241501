.file-card {
    display: flex;
    gap: 8px;
    padding: 10px 8px;
    margin-bottom: 8px;
    background-color: #ffffff !important;
    border: 1px solid var(--clr-grey-default);
    border-radius: 8px;
}

.file-card-title {
    word-break: break-word;
}

.file-card-subtitle {
    color: var(--clr-grey-dark);
}

.file-card-type-icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--clr-grey-light);
    border-radius: 6px;
}

.file-card-close-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--clr-info-error-default);
}

.file-lock {
    display: flex;
    justify-content: center;
    gap: 8px;
    color: var(--clr-brand-default);
    cursor: pointer;
}