.user-page-title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.user-avatar-section {
  margin: 25px 0;
}

.user-page-subtitle {
  font-size: 16px;
  font-weight: 700;
}

.text-thin {
  font-weight: 300;
}

.user-details-title {
  color: #707070;
}

.user-details p:not(:last-child) {
  margin-bottom: 10px;
}
.user-details-item-left {
  width: 210px;
}
.user-details-item-right {
  font-weight: 600;
}

.doc-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: whitesmoke;
  border-radius: 5px;
  min-height: 120px;
  margin: 4px;
  color: lightgrey;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-decoration: none !important;
}
.grey-light-bg {
  background-color: var(--clr-grey-light);
}

.details-section {
  margin-top: 32px;
}

.details-proof {
  padding: 16px;
  background: #eff6ff;
}

.horizontal-line {
  width: 95%;
  margin: 0 auto;
  border: none;
  border-top: 1px solid #f0f0f0;
}
