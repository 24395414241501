.rf-doc-viewer {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.rf-doc-viewer__doc {
  margin: auto;
  display: block;
  max-width: 100vw;
  max-height: 90vh;
  padding: 0 20px;
  animation-name: zoom;
  animation-duration: 0.6s;
  overflow-y: auto;
}
.rf-doc-viewer__doc img {
  width: 100%;
}
.rf-doc-viewer__doc.embedded-view {
  width: 80%;
  height: 85%;
}
.rf-doc-viewer__doc iframe {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.rf-doc-viewer__close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.rf-doc-viewer__close:hover,
.rf-doc-viewer__close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.rf-doc-viewer__fallback-text {
    color: #fff;
}

@media only screen and (max-width: 700px) {
  .rf-doc-viewer__doc {
    padding: 0 10px;
  }
  .rf-doc-viewer__doc.embedded-view {
    width: 100%;
    height: 80%;
  }
}
