.info-card {
    padding: 24px;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
}

.info-card.clickable {
    cursor: pointer;
}

.info-card__success {
    border-left: 6px solid var(--clr-info-success-default);
}
.info-card__info {
    border-left: 6px solid var(--clr-brand-default);
}
.info-card__warning {
    border-left: 6px solid var(--clr-info-alert-default);
}

.info-card__label {
    font-size: 18px;
}

.info-card__content {
    font-size: 24px;
    font-weight: 500;

    display: flex;
    align-items: center;
}