.drop-zone {
  position: relative;
  height: 12.5rem;
  padding: 1.25rem;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.disabled {
  color: var(--clr-grey-default);
  border-color: var(--clr-grey-default);
}

.fileLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.fileElem {
  display: none;
}

.icon-container {
  background-color: var(--clr-grey-light);
  width: 4rem;
  height: 4rem;
  border: 1px solid var(--clr-grey-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-zone-text {
  margin-bottom: 0;
  font-weight: 500;
}

.highlight-text {
  color: var(--clr-brand-default);
}
