.rf-box {
  margin-bottom: 10px;
}

.rf-box-header {
  padding: 12px 32px;
  background-color: transparent;
}

.rf-box-content {
  padding: 24px 32px;
  background-color: var(--clr-pure-white);
}

@media screen and (max-width: 768px) {
  .rf-box-header {
    padding: 8px 24px;
  }

  .rf-box-content {
    padding: 8px 24px;
  }
}
