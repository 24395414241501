:root {
  --primary: #003D80;
  --secondary: #0057B7;
  --lightbg: #FDFDFD;
  --textgray: #898989;
  --max-width: 100vw;
  --white: #ffffff;
  --lightSky: #F1FAFF;
  --lightgray: #9e9e9e;
  --placeholderColor: #8B8B8B;
  --lightBlueBg: #F5F5FC;
  --lightGreen: #ECFFF1;
  --green: #43B96F;
  --lightDanger: #FFF5F2;
  --danger: #DA7C2A;

  /* Branding Colours */
  --clr-brand-default: #0057B7;
  --clr-brand-light: #E5EEF8;
  --clr-brand-dark: #003D80;

  --clr-text-primary: #15171A;
  --clr-text-secondary: #696D70;
  --clr-text-tertiary: #999EA3;

  --clr-grey-default: #C9CDD1;
  --clr-grey-light: #F5F7FA;
  --clr-grey-dark: #B8BBBF;

  --clr-pure-white: #FFFFFF;

  --clr-info-error-default: #D13B3B;
  --clr-info-error-light: #F8E2E2;
  --clr-info-error-dark: #781313;

  --clr-info-success-default: #2C9E82;
  --clr-info-success-light: #DCF5EF;
  --clr-info-success-dark: #0C7057;

  --clr-info-alert-default: #EC9527;
  --clr-info-alert-light: #FCF2E3;
  --clr-info-alert-dark: #8A5006;

  --clr-info-highlight-default: #F5C245;
  --clr-info-highlight-light: #FCEECA;
  --clr-info-highlight-dark: #AC7D08;
}

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");

* {
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
}

.heading {
  font-size: 30px;
  color: var(--primary);
  font-weight: 700;
}
.subheading {
  color: var(--primary);
  font-weight: 500;
}

.w-50 {
  width: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  background-color: var(--secondary);
  padding: 8px 35px;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  transition: 0.5s;
}

.btn-primary:hover {
  background-color: var(--secondary);
}

.btn-translucent {
  background-color: var(--lightSky);
  color: var(--secondary);
  font-size: 10px;
  font-weight: 700;
}

.btn-pending {
  background-color: var(--lightSky);
  color: var(--secondary);
  font-size: 10px;
  padding: 5px 20px;
}

.btn-verified {
  background-color: var(--lightGreen);
  color: var(--green);
  font-size: 10px;
  padding: 5px 20px;
}

.btn-rejected {
  background-color: var(--lightDanger);
  color: var(--danger);
  font-size: 10px;
  padding: 5px 20px;
}

.table-wrapper {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  background: white;
  border-radius: 8px;
  padding: 50px 50px 25px 50px;
}

.lightLabel {
  color: var(--textgray);
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.no-text-decoration {
  text-decoration: none !important;
}

.grey-text {
  color: var(--textgray);
}

.primarycolor {
  color: var(--secondary) !important;
}

.secondary-color {
  color: var(--primary) !important;
}

.word-break {
  word-break: break-word;
}

.btn1 {
  background-color: var(--primary);
  color: white;
}

.btn2 {
  background-color: var(--secondary);
  color: white;
  height: 48px;
  border-radius: 4px;
}

.btn2:hover {
  color: var(--white);
}

.input-login {
  background-color: var(--lightbg) !important;
  border: #e4e5e7 !important;
  font-size: small;
}

.only-icon {
  padding: 8px 16px !important;
}

.search-input {
  width: 40%;
  text-align: center;
  background-color: var(--lightbg);
  border: 1px solid #e6e6e6;
}

.search-input::placeholder {
  color: var(--placeholderColor);
  font-size: 14px;
}

.search-input-user {
  width: 300px;
}

.input-login:-webkit-autofill,
.input-login:-webkit-autofill:hover,
.input-login:-webkit-autofill:focus,
.input-login:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--primary);
}

.font-weight-bold {
  font-weight: bold;
}
.font-size-small {
  font-size: small;
}
.color-white {
  color: #ffffff;
}
.border-dash {
  border-style: dashed;
}
.display-none {
  display: none;
}
.white-bg {
  background-color: #ffffff !important;
}
.tnc-font {
  font-size: 9px;
}
.cursor-pointer {
  cursor: pointer;
}

.primary-color {
  color: var(--primary);
}
.secondary-color {
  color: var(--secondary);
}

.table-input-box {
  border-radius: 20px;
  border: 1px solid var(--primary);
  max-width: 220px;
  font-size: 14px;
  margin-left: 42px;
  padding: 5px;
  height: 38px;
  text-align: center;
  color: var(--primary);
  font-weight: 700;
}

/* form input css start */
.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-signin-label-group {
  position: relative;
  margin-bottom: 10px;
}

.form-control:focus {
  border: 1px solid var(--textgray);
  box-shadow: none;
}

.form-signin-label-group > input,
.form-signin-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: #58585829;
}

.form-signin-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: var(--textgray);
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-signin-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-signin-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-signin-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-signin-label-group input::-moz-placeholder {
  color: transparent;
}

.form-signin-label-group input::placeholder {
  color: transparent;
}

.form-signin-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-signin-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 10.6px;
  color: var(--textgray) !important;
}

.form-group-white-bg {
  background-color: #ffffff !important;
  border-bottom: 1px solid #d6d6d6;
}
.form-group-white-bg > label {
  background-color: transparent !important;
}

.search-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}

/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-signin-label-group > label {
    display: none;
  }
  .form-signin-label-group input::-ms-input-placeholder {
    color: var(--primary) !important;
  }
}

/* Fallback for IE
  -------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-signin-label-group > label {
    display: none;
  }
  .form-signin-label-group input:-ms-input-placeholder {
    color: var(--primary);
  }
}

/* form input css closed */

@media only screen and (max-width: 998px) {
  .search-input-user {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .table-wrapper {
    padding: 20px 15px;
    border-radius: 0px;
  }

  .pencil-button {
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 100;
  }

  .search-input {
    width: 100%;
  }
  .heading {
    font-size: 20px;
  }

  .w-md-100 {
    width: 100%;
  }
}

button svg {
  margin-right: 5px;
}

.rf-btn-group button:not(:last-child) {
  margin-right: 20px;
}

.dashboard-container {
  background-color: var(--white);
  padding: 40px;
  border-radius: 8px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    padding: 20px;
  }
}
