.rf-btn {
  --primary-blue: var(--clr-brand-default);
  --primary-blue-hover: var(--clr-brand-dark);
  --success-green: var(--clr-info-success-default);
  --success-green-hover: var(--clr-info-success-dark);
  --danger-red: var(--clr-info-error-default);
  --danger-red-hover: var(--clr-info-error-dark);
  --info-grey: var(--clr-grey-default);
  --light-blue: var(--clr-brand-default);
  --light-blue-bg: var(--clr-brand-light);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: var(--clr-pure-white);
}

.rf-btn:disabled {
  color: var(--clr-text-secondary) !important;
  background-color: var(--info-grey) !important;
}

.rf-btn-primary {
  background-color: var(--primary-blue);
}
.rf-btn-primary:hover {
  color: #fff;
  background-color: var(--primary-blue-hover);
}

.rf-btn-success {
  background-color: var(--success-green);
}
.rf-btn-success:hover {
  color: #fff;
  background-color: var(--success-green-hover);
}

.rf-btn-danger,
.rf-btn-danger:hover {
  color: var(--danger-red);
}

.rf-btn-danger-fill {
  background-color: var(--danger-red);
}
.rf-btn-danger-fill:hover {
  color: #fff;
  background-color: var(--danger-red-hover);
}

.rf-btn-info,
.rf-btn-info:hover {
  color: var(--info-grey);
}

.rf-btn-light-blue,
.rf-btn-light-blue:hover {
  color: var(--light-blue);
  background-color: var(--light-blue-bg);
}

.rf-btn-blue-outline {
  background-color: #fff;
  color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
}
.rf-btn-blue-outline:hover {
  background-color: var(--primary-blue-hover);
  color: #fff;
}

.rf-btn-danger-outline {
  background-color: #fff;
  color: var(--danger-red);
  border: 1px solid var(--danger-red);
}
.rf-btn-danger-outline:hover {
  background-color: var(--danger-red-hover);
  color: #fff;
}

.rf-btn-success-outline {
  background-color: #fff;
  color: var(--success-green);
  border: 1px solid var(--success-green);
}
.rf-btn-success-outline:hover {
  background-color: var(--success-green-hover);
  color: #fff;
}

.rf-btn-blue,
.rf-btn-blue:hover {
  color: var(--primary-blue);
}

.rf-btn-blue-text,
.rf-btn-blue-text:hover{
  color: var(--primary-blue);
  border: none;
}

.rf-btn-blue-text:focus {
  box-shadow: none;
}

.rf-btn-text,
.rf-btn-text:hover{
  color: #000;
  border: none;
}

.rf-btn-danger-text,
.rf-btn-danger-text:hover {
  background-color: #fff;
  color: var(--danger-red);
}

.rf-btn-text:focus,
.rf-btn-danger-text:focus {
  box-shadow: none;
}

.rf-btn-xs {
  padding: unset;
}
.rf-btn-sm {
  padding: 8px 18px;
}
.rf-btn-md {
  padding: 10px 18px;
  min-width: 150px;
}
.rf-btn-lg {
  padding: 12px 20px;
  min-width: 200px;
}

.rf-btn-icon-only {
  padding: 5px;
}

.rf-btn-icon-only svg {
  margin: 0;
}
