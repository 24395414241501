.rf-checkbox__wrapper {
    --checkbox-width: 20px;
    --checkbox-bg-clr: var(--clr-pure-white);
    --checkbox-border-clr: var(--clr-grey-default);
    --checkbox-checked-bg-clr: var(--clr-brand-default);
    --checkbox-font-size: 14px;
    --checkbox-font-line-height: 20px;
    --checkbox-font-weight: 500;
  
    display: flex;
    align-items: center;
  }
  
  .rf-checkbox__inner {
    position: relative;
    width: var(--checkbox-width);
    height: var(--checkbox-width);
  }
  .rf-checkbox__inner-size-sm {
    --checkbox-width: 16px;
  }
  .rf-checkbox__inner-position-left {
    order: 2;
  }
  .rf-checkbox__inner-position-right {
    order: 1;
  }
  
  .rf-checkbox__input {
    appearance: none;
    background-color: var(--checkbox-bg-clr);
    border: 1px solid var(--checkbox-border-clr);
    width: var(--checkbox-width);
    height: var(--checkbox-width);
    border-radius: 4px;
    display: block;
    padding: 0;
    margin: 0;
    transition: border-color 100ms ease, background-color 100ms ease;
    cursor: pointer;
  }
  
  .rf-checkbox__input:focus {
    border: 1px solid var(--clr-brand-default);
    box-shadow: 0px 0px 0px 4px var(--clr-brand-light);
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  
  /* TODO: Figure indeterminate */
  .rf-checkbox__inner-icon {
    color: var(--checkbox-bg-clr);
  
    pointer-events: none;
    width: calc(var(--checkbox-width) / 2);
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: auto;
  }
  
  /* TODO: Clear about it's usecase
  .rf-checkbox__input-indeterminate + .rf-checkbox__inner-icon {
      color: inherit;
      opacity: 1;
  } */
  
  .rf-checkbox__input:checked {
    background-color: var(--checkbox-checked-bg-clr);
    border-color: var(--checkbox-checked-bg-clr);
  }
  
  .rf-checkbox__input:checked + .rf-checkbox__inner-icon {
    opacity: 1;
    color: var(--clr-pure-white);
    transform: translateY(0) scale(1);
  }
  
  .rf-checkbox__input:hover:not(:disabled) {
    --checkbox-bg-clr: var(--clr-brand-light);
    --checkbox-border-clr: var(--clr-brand-default);
  }
  
  .rf-checkbox__input:hover:checked:not(:disabled) {
    --checkbox-bg-clr: var(--clr-brand-dark);
  }
  
  .rf-checkbox__input:disabled {
    background-color: var(--clr-grey-light);
    border-color: var(--checkbox-border-clr);
    cursor: not-allowed;
  }
  
  .rf-checkbox__input:disabled + .rf-checkbox__inner-icon {
    color: var(--checkbox-border-clr);
  }
  
  .rf-checkbox__label {
    font-family: inherit;
    font-size: var(--checkbox-font-size);
    font-weight: var(--checkbox-font-weight);
    line-height: var(--checkbox-font-line-height);
    color: var(--clr-text-primary);
    margin: 0;
  }
  
  .rf-checkbox__label-position-left {
    order: 1;
    padding-right: 8px;
  }
  .rf-checkbox__label-position-right {
    order: 2;
    padding-left: 8px;
  }
  