.delta-indicator-chip {
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delta-indicator-chip__increment {
    background-color: var(--clr-info-success-light);
    color: var(--clr-info-success-default);
}
.delta-indicator-chip__decrement {
    background-color: var(--clr-info-alert-light);
    color: var(--clr-info-alert-default);
}