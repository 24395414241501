:root {
  --sidenav-width: 250px;
  --sidenav-collapse-width: 80px;
  --font-color: var(--clr-text-primary);
  --link-color: var(--clr-text-secondary);
  --link-active-border: var(--clr-brand-default);
}

/* TODO: Find a good way to do it */
body {
  background-color: #f7f8fa !important;
  color: var(--font-color);
}

.page-wrapper {
  display: flex;
  width: 100%;
}

.sidenav {
  min-width: var(--sidenav-width);
  max-width: var(--sidenav-width);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  /* z-index: 999; */
  background: #fff;
  color: var(--link-color);
  font-size: 16px;
  font-weight: 400;
  padding: 40px 0;
  transition: all 0.3s;
}

.content {
  width: calc(100% - var(--sidenav-width));
  padding: 40px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.sidenav .sidenav-brand {
  padding: 0 40px;
  max-width: 200px;
}

.sidenav-brand svg {
  width: 100%;
  height: 100%;
}

.sidenav-links {
  margin-top: 60px;
}
.sidenav-link:not(:last-child) {
  margin-bottom: 10px;
}
.sidenav-link a,
.sidenav-link button {
  width: 100%;
  color: inherit;
  padding: 10px 0;
  text-decoration: none !important;
  padding-left: 40px;
}

.sidenav-link a:hover,
.sidenav-link button:hover {
  background-color: var(--clr-brand-light);
  color: inherit;
}
.sidenav-link .link-active {
  border-right: solid 6px var(--link-active-border);
  color: var(--font-color);
  fill: var(--font-color);
  font-weight: 700;
}
.dropdown-toggle {
  position: relative;
}
.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.topbar {
  margin-bottom: 50px;
}
.topbar-left {
  font-family: inherit;
  font-size: 20px;
  font-weight: 700;
  color: var(--clr-brand-default);
}
.topbar-right {
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
}

.topbar-right .topbar-username {
  margin-right: 20px;
}
@media (max-width: 768px) {
  .sidenav {
    min-width: var(--sidenav-collapse-width);
    max-width: var(--sidenav-collapse-width);
    text-align: center;
  }
  .content {
    width: calc(100% - 85px);
    padding: 40px 10px;
  }
  .sidenav .sidenav-brand {
    padding: 0 20px;
  }
  .sidenav-link a, .sidenav-link button {
    padding-left: 30px;
  }
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
