.rf-card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: #fff;
    font-family: inherit;
    font-size: 38px;
    font-weight: 900;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.rf-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 25px;
    background-color: #fff;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    color: var(--font-color);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-decoration: none !important;
}