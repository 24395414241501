.alert-success {
    color: var(--clr-info-success-dark);
    background-color: var(--clr-info-success-light);
    border-color: var(--clr-info-success-light);
}

.alert-danger {
    color: var(--clr-info-error-dark);
    background-color: var(--clr-info-error-light);
    border-color: var(--clr-info-error-light);
}

.alert-warning {
    color: var(--clr-info-alert-dark);
    background-color: var(--clr-info-alert-light);
    border-color: var(--clr-info-warning-light);
}

.alert-info {
    color: var(--clr-info-highlight-dark);
    background-color: var(--clr-info-highlight-light);
    border-color: var(--clr-info-highlight-light);
}