.bank_statements_layout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    gap: 2rem;
}
/* no var available for color ADD4FF */
.bank_statements_table table,
.bank_statements_table th,
.bank_statements_table td {
    border-collapse: collapse;
    border: 1px solid #ADD4FF !important;
}

.file-card-container {
    max-height: 20rem;
    margin-bottom: 8px;
    overflow: auto;
    overflow-x: hidden;
}

.document-previewer {
    position: -webkit-sticky;
    position: sticky;
    top: 2px;
}
