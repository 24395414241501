.custom-badge {
  padding: 4px 8px;
  color: var(--clr-pure-white);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: linear-gradient(257.7deg, #0057b7 0%, #001747 95.45%);
  text-align: center;
  max-width: fit-content;
  border-radius: 4px;
}

.custom-badge.rounded {
  border-radius: 100px !important;
}

.custom-badge.full-width {
  width: 100%;
  max-width: unset;
}
