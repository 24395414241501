.table-comp {
    --table-header-bg-color: var(--clr-grey-light);
    --table-border-color: var(--clr-grey-default);
    --table-link-color: var(--clr-brand-default);
}

.table-comp table {
    font: inherit;
    font-size: 16px;
    font-weight: 400;
    color: inherit;
}

.table-comp thead,
.table-comp tfoot {
    background-color: var(--table-header-bg-color);
}

.table-comp td, .table-comp th {
    padding: 20px;
}
.table-comp tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--table-border-color)
}

.table-comp .table-link {
    color: var(--table-link-color);
    font-weight: 700;
    text-decoration: none;
}