@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap);
.delta-indicator-chip {
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delta-indicator-chip__increment {
    background-color: var(--clr-info-success-light);
    color: var(--clr-info-success-default);
}
.delta-indicator-chip__decrement {
    background-color: var(--clr-info-alert-light);
    color: var(--clr-info-alert-default);
}
.info-card {
    padding: 24px;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
}

.info-card.clickable {
    cursor: pointer;
}

.info-card__success {
    border-left: 6px solid var(--clr-info-success-default);
}
.info-card__info {
    border-left: 6px solid var(--clr-brand-default);
}
.info-card__warning {
    border-left: 6px solid var(--clr-info-alert-default);
}

.info-card__label {
    font-size: 18px;
}

.info-card__content {
    font-size: 24px;
    font-weight: 500;

    display: flex;
    align-items: center;
}
.rf-card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: #fff;
    font-family: inherit;
    font-size: 38px;
    font-weight: 900;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.rf-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 25px;
    background-color: #fff;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    color: var(--font-color);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-decoration: none !important;
}
.rf-btn {
  --primary-blue: var(--clr-brand-default);
  --primary-blue-hover: var(--clr-brand-dark);
  --success-green: var(--clr-info-success-default);
  --success-green-hover: var(--clr-info-success-dark);
  --danger-red: var(--clr-info-error-default);
  --danger-red-hover: var(--clr-info-error-dark);
  --info-grey: var(--clr-grey-default);
  --light-blue: var(--clr-brand-default);
  --light-blue-bg: var(--clr-brand-light);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: var(--clr-pure-white);
}

.rf-btn:disabled {
  color: var(--clr-text-secondary) !important;
  background-color: var(--info-grey) !important;
}

.rf-btn-primary {
  background-color: var(--primary-blue);
}
.rf-btn-primary:hover {
  color: #fff;
  background-color: var(--primary-blue-hover);
}

.rf-btn-success {
  background-color: var(--success-green);
}
.rf-btn-success:hover {
  color: #fff;
  background-color: var(--success-green-hover);
}

.rf-btn-danger,
.rf-btn-danger:hover {
  color: var(--danger-red);
}

.rf-btn-danger-fill {
  background-color: var(--danger-red);
}
.rf-btn-danger-fill:hover {
  color: #fff;
  background-color: var(--danger-red-hover);
}

.rf-btn-info,
.rf-btn-info:hover {
  color: var(--info-grey);
}

.rf-btn-light-blue,
.rf-btn-light-blue:hover {
  color: var(--light-blue);
  background-color: var(--light-blue-bg);
}

.rf-btn-blue-outline {
  background-color: #fff;
  color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
}
.rf-btn-blue-outline:hover {
  background-color: var(--primary-blue-hover);
  color: #fff;
}

.rf-btn-danger-outline {
  background-color: #fff;
  color: var(--danger-red);
  border: 1px solid var(--danger-red);
}
.rf-btn-danger-outline:hover {
  background-color: var(--danger-red-hover);
  color: #fff;
}

.rf-btn-success-outline {
  background-color: #fff;
  color: var(--success-green);
  border: 1px solid var(--success-green);
}
.rf-btn-success-outline:hover {
  background-color: var(--success-green-hover);
  color: #fff;
}

.rf-btn-blue,
.rf-btn-blue:hover {
  color: var(--primary-blue);
}

.rf-btn-blue-text,
.rf-btn-blue-text:hover{
  color: var(--primary-blue);
  border: none;
}

.rf-btn-blue-text:focus {
  box-shadow: none;
}

.rf-btn-text,
.rf-btn-text:hover{
  color: #000;
  border: none;
}

.rf-btn-danger-text,
.rf-btn-danger-text:hover {
  background-color: #fff;
  color: var(--danger-red);
}

.rf-btn-text:focus,
.rf-btn-danger-text:focus {
  box-shadow: none;
}

.rf-btn-xs {
  padding: unset;
}
.rf-btn-sm {
  padding: 8px 18px;
}
.rf-btn-md {
  padding: 10px 18px;
  min-width: 150px;
}
.rf-btn-lg {
  padding: 12px 20px;
  min-width: 200px;
}

.rf-btn-icon-only {
  padding: 5px;
}

.rf-btn-icon-only svg {
  margin: 0;
}

.rf-checkbox__wrapper {
    --checkbox-width: 20px;
    --checkbox-bg-clr: var(--clr-pure-white);
    --checkbox-border-clr: var(--clr-grey-default);
    --checkbox-checked-bg-clr: var(--clr-brand-default);
    --checkbox-font-size: 14px;
    --checkbox-font-line-height: 20px;
    --checkbox-font-weight: 500;
  
    display: flex;
    align-items: center;
  }
  
  .rf-checkbox__inner {
    position: relative;
    width: var(--checkbox-width);
    height: var(--checkbox-width);
  }
  .rf-checkbox__inner-size-sm {
    --checkbox-width: 16px;
  }
  .rf-checkbox__inner-position-left {
    order: 2;
  }
  .rf-checkbox__inner-position-right {
    order: 1;
  }
  
  .rf-checkbox__input {
    -webkit-appearance: none;
            appearance: none;
    background-color: var(--checkbox-bg-clr);
    border: 1px solid var(--checkbox-border-clr);
    width: var(--checkbox-width);
    height: var(--checkbox-width);
    border-radius: 4px;
    display: block;
    padding: 0;
    margin: 0;
    transition: border-color 100ms ease, background-color 100ms ease;
    cursor: pointer;
  }
  
  .rf-checkbox__input:focus {
    border: 1px solid var(--clr-brand-default);
    box-shadow: 0px 0px 0px 4px var(--clr-brand-light);
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  
  /* TODO: Figure indeterminate */
  .rf-checkbox__inner-icon {
    color: var(--checkbox-bg-clr);
  
    pointer-events: none;
    width: calc(var(--checkbox-width) / 2);
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: auto;
  }
  
  /* TODO: Clear about it's usecase
  .rf-checkbox__input-indeterminate + .rf-checkbox__inner-icon {
      color: inherit;
      opacity: 1;
  } */
  
  .rf-checkbox__input:checked {
    background-color: var(--checkbox-checked-bg-clr);
    border-color: var(--checkbox-checked-bg-clr);
  }
  
  .rf-checkbox__input:checked + .rf-checkbox__inner-icon {
    opacity: 1;
    color: var(--clr-pure-white);
    transform: translateY(0) scale(1);
  }
  
  .rf-checkbox__input:hover:not(:disabled) {
    --checkbox-bg-clr: var(--clr-brand-light);
    --checkbox-border-clr: var(--clr-brand-default);
  }
  
  .rf-checkbox__input:hover:checked:not(:disabled) {
    --checkbox-bg-clr: var(--clr-brand-dark);
  }
  
  .rf-checkbox__input:disabled {
    background-color: var(--clr-grey-light);
    border-color: var(--checkbox-border-clr);
    cursor: not-allowed;
  }
  
  .rf-checkbox__input:disabled + .rf-checkbox__inner-icon {
    color: var(--checkbox-border-clr);
  }
  
  .rf-checkbox__label {
    font-family: inherit;
    font-size: var(--checkbox-font-size);
    font-weight: var(--checkbox-font-weight);
    line-height: var(--checkbox-font-line-height);
    color: var(--clr-text-primary);
    margin: 0;
  }
  
  .rf-checkbox__label-position-left {
    order: 1;
    padding-right: 8px;
  }
  .rf-checkbox__label-position-right {
    order: 2;
    padding-left: 8px;
  }
  
.table-comp {
    --table-header-bg-color: var(--clr-grey-light);
    --table-border-color: var(--clr-grey-default);
    --table-link-color: var(--clr-brand-default);
}

.table-comp table {
    font: inherit;
    font-size: 16px;
    font-weight: 400;
    color: inherit;
}

.table-comp thead,
.table-comp tfoot {
    background-color: var(--table-header-bg-color);
}

.table-comp td, .table-comp th {
    padding: 20px;
}
.table-comp tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--table-border-color)
}

.table-comp .table-link {
    color: var(--table-link-color);
    font-weight: 700;
    text-decoration: none;
}
.rf-badge {
    --badge-success: var(--clr-info-success-default);
    --badge-success-bg: var(--clr-info-success-light);
    --badge-pending: var(--clr-info-alert-default);
    --badge-pending-bg: var(--clr-info-alert-light);
    --badge-reject: var(--clr-info-error-default);
    --badge-reject-bg: var(--clr-info-error-light);

    width: 100%;
    text-align: center;
    font-family: inherit;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
}

.rf-badge.capitalize {
    text-transform: capitalize;
}

.rf-badge-success {
    color: var(--badge-success);
    background-color: var(--badge-success-bg);
}
.rf-badge-pending {
    color: var(--badge-pending);
    background-color: var(--badge-pending-bg);
}
.rf-badge-reject {
    color: var(--badge-reject);
    background-color: var(--badge-reject-bg);
}
.rf-select {
    --text-color: var(--clr-text-primary);
    --bg-color: var(--clr-pure-white);
    --border-color: var(--clr-grey-light);
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    margin: 0;
    width: 100%;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.rf-label {
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
}

.rf-range {
    width: 100%;
}

.rf-range-input {
    --text-color: var(--clr-primary-default);
    --bg-color: #FCFCFC;
    --border-color: #DBDBDB;
    max-width: 120px;
    padding: 5px 10px;
    width: 100%;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    text-align: center;
}

.rf-range-val {
    --text-color: var(--clr-text-primary);
    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
    color: var(--text-color);
}

.rf-search {
    padding: 25px 10px;
    padding-right: 20px;
}

.rf-input-clear-btn {
    margin-left: -40px; 
    z-index: 100;
}

.rf-input-star {
    color: red;
}

.rf-form-msg {
    font-size: 12px;
    font-weight: 400;
    margin: 5px 0;
}

.form-group:last-child {
    margin-bottom: 0;
}

.search-box__label {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
}
.rf-pagination {
  margin: 10px 0;
}

.rf-pagination__relative-entry-count {
  margin: 0 10px;
}

.rf-pagination__list {
  list-style: none;
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;
}

.rf-pagination__item {
  -webkit-user-select: none;
          user-select: none;
}

.rf-pagination__item:not(:last-child) {
  margin-right: 8px;
}

.rf-pagination__btn {
  background: none;
  padding: 5px 10px;
  color: var(--clr-brand-default);
  border: 1px solid transparent;
  border-radius: 6px;
  transition: border-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
}
.rf-pagination__btn:hover {
  border-color: var(--clr-brand-default);
  outline: 0;
  transition-duration: 0.1s;
}
.rf-pagination__btn.active {
  color: var(--clr-pure-white);
  background-color: var(--clr-brand-default);
  border-color: transparent;
  pointer-events: none;
}

.rf-pagination__btn--action {
  background: none;
  max-width: 32px;
  padding: 5px 10px;
  color: var(--clr-brand-default);
  border: none;
}

.rf-pagination__btn--action:disabled {
  color: #4d4f5c;
  opacity: 0.6;
}

.rf-pagination__jump-to {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-left: 20px;
}

.rf-pagination__jump-to--text {
  margin-right: 10px;
}

.rf-pagination__jump-to--input {
  width: 50px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.rf-pagination__per-page-selector {
  background: transparent;
  border: none;
  padding: 0;
}

.rf-modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.rf-modal-content {
  padding: 20px;
}

.rf-modal-header, 
.rf-modal-footer {
  border: none;
}
.rf-modal-footer {
  justify-content: flex-start;
}
.alert-success {
    color: var(--clr-info-success-dark);
    background-color: var(--clr-info-success-light);
    border-color: var(--clr-info-success-light);
}

.alert-danger {
    color: var(--clr-info-error-dark);
    background-color: var(--clr-info-error-light);
    border-color: var(--clr-info-error-light);
}

.alert-warning {
    color: var(--clr-info-alert-dark);
    background-color: var(--clr-info-alert-light);
    border-color: var(--clr-info-warning-light);
}

.alert-info {
    color: var(--clr-info-highlight-dark);
    background-color: var(--clr-info-highlight-light);
    border-color: var(--clr-info-highlight-light);
}
.multi-select__container {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}

.multi-select__label {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}

.multi-select__box {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  padding: 4px;
  min-height: 52px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multi-select__box.active {
  box-shadow: 0px 0px 0px 1px grey;
}

.multi-select__box--placeholder {
  margin-left: 5px;
  opacity: 0.7;
  -webkit-user-select: none;
          user-select: none;
}

.multi-select__box-badge__container {
  display: flex;
  flex-wrap: wrap;
}

.multi-select__box-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  margin: 3px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.multi-select__box-badge--close-btn {
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.multi-select__box__action-btn-group {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.multi-select__box__action-indicator {
  color: #ced1da;
}

.multi-select__box__action-btn {
  padding: 5px;
  cursor: pointer;
  opacity: 0.6;
}
.multi-select__box__action-btn:hover {
  opacity: 1;
}

.multi-select__option-list-container {
  display: none;
  position: absolute;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 10;
}

.multi-select__option-list-container.active {
  display: block;
}

.multi-select__option-list {
  display: block;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 250px;
  overflow-y: auto;
  list-style: none;
}

.multi-select__option-list-item {
  padding: 0 15px;
}

.multi-select__option-list-item--group-title {
  opacity: 0.7;
  text-transform: uppercase;
  padding: 10px;
  -webkit-user-select: none;
          user-select: none;
}

.multi-select__option-list-item--checkbox-label {
  margin: 0;
  padding: 10px;
  width: 100%;
  cursor: pointer;

  display: flex;
  align-items: center;
}

.multi-select__option-list-item--checkbox-label-text {
  margin-left: 10px;
}

.user-page-title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.user-avatar-section {
  margin: 25px 0;
}

.user-page-subtitle {
  font-size: 16px;
  font-weight: 700;
}

.text-thin {
  font-weight: 300;
}

.user-details-title {
  color: #707070;
}

.user-details p:not(:last-child) {
  margin-bottom: 10px;
}
.user-details-item-left {
  width: 210px;
}
.user-details-item-right {
  font-weight: 600;
}

.doc-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: whitesmoke;
  border-radius: 5px;
  min-height: 120px;
  margin: 4px;
  color: lightgrey;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-decoration: none !important;
}
.grey-light-bg {
  background-color: var(--clr-grey-light);
}

.details-section {
  margin-top: 32px;
}

.details-proof {
  padding: 16px;
  background: #eff6ff;
}

.horizontal-line {
  width: 95%;
  margin: 0 auto;
  border: none;
  border-top: 1px solid #f0f0f0;
}

.rf-nav-tab-wrapper {
    background-color: var(--clr-pure-white);
    margin-bottom: 42px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.rf-nav-tab {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    color: var(--clr-text-secondary);
    font-weight: 500;
}

.rf-nav-tab-item {
    flex: 1 1 auto;
    text-align: center;
}

.rf-nav-tab-link {
    color: var(--clr-text-secondary);
    text-decoration: none;
    padding: 16px 0;
}

.rf-nav-tab-link:hover {
    text-decoration: none;
}

.rf-nav-tab-link.active {
    font-weight: 600;
    color: var(--clr-brand-default);
    border-bottom: 2px solid var(--clr-brand-default);
}
.rf-box {
  margin-bottom: 10px;
}

.rf-box-header {
  padding: 12px 32px;
  background-color: transparent;
}

.rf-box-content {
  padding: 24px 32px;
  background-color: var(--clr-pure-white);
}

@media screen and (max-width: 768px) {
  .rf-box-header {
    padding: 8px 24px;
  }

  .rf-box-content {
    padding: 8px 24px;
  }
}

.custom-alert {
    background-color: var(--clr-pure-white);
    /* TODO: Magic number here, fix this */
    padding: 12px;
    border-radius: 6px;

    font-weight: 500;
    font-size: 15px;
    /* INFO: This is a hack */
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: 50%;
}

.custom-alert-full-width{
    width: 100%;
    max-width: unset;
}

.custom-alert-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-alert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-alert__success {
    border: 1px solid var(--clr-info-success-default);
}
.custom-alert__success .custom-alert-icon {
    color: var(--clr-info-success-default);
}

.custom-alert__danger {
    border: 1px solid var(--clr-info-error-default);
}
.custom-alert__danger .custom-alert-icon {
    color: var(--clr-info-error-default);
}

.custom-alert__warning {
    border: 1px solid var(--clr-info-alert-default);
}
.custom-alert__warning .custom-alert-icon {
    color: var(--clr-info-alert-default);
}

.custom-badge {
  padding: 4px 8px;
  color: var(--clr-pure-white);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: linear-gradient(257.7deg, #0057b7 0%, #001747 95.45%);
  text-align: center;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 4px;
}

.custom-badge.rounded {
  border-radius: 100px !important;
}

.custom-badge.full-width {
  width: 100%;
  max-width: unset;
}

.rf-doc-viewer {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.rf-doc-viewer__doc {
  margin: auto;
  display: block;
  max-width: 100vw;
  max-height: 90vh;
  padding: 0 20px;
  animation-name: zoom;
  animation-duration: 0.6s;
  overflow-y: auto;
}
.rf-doc-viewer__doc img {
  width: 100%;
}
.rf-doc-viewer__doc.embedded-view {
  width: 80%;
  height: 85%;
}
.rf-doc-viewer__doc iframe {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.rf-doc-viewer__close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.rf-doc-viewer__close:hover,
.rf-doc-viewer__close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.rf-doc-viewer__fallback-text {
    color: #fff;
}

@media only screen and (max-width: 700px) {
  .rf-doc-viewer__doc {
    padding: 0 10px;
  }
  .rf-doc-viewer__doc.embedded-view {
    width: 100%;
    height: 80%;
  }
}

.nav-pills .nav-link {
    background-color: var(--clr-pure-white);
    color: var(--clr-brand-default);
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--clr-pure-white);
    background-color: var(--clr-brand-default);
}
.bank_statements_layout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
}
/* no var available for color ADD4FF */
.bank_statements_table table,
.bank_statements_table th,
.bank_statements_table td {
    border-collapse: collapse;
    border: 1px solid #ADD4FF !important;
}

.file-card-container {
    max-height: 20rem;
    margin-bottom: 8px;
    overflow: auto;
    overflow-x: hidden;
}

.document-previewer {
    position: -webkit-sticky;
    position: sticky;
    top: 2px;
}

.drop-zone {
  position: relative;
  height: 12.5rem;
  padding: 1.25rem;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.disabled {
  color: var(--clr-grey-default);
  border-color: var(--clr-grey-default);
}

.fileLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.fileElem {
  display: none;
}

.icon-container {
  background-color: var(--clr-grey-light);
  width: 4rem;
  height: 4rem;
  border: 1px solid var(--clr-grey-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-zone-text {
  margin-bottom: 0;
  font-weight: 500;
}

.highlight-text {
  color: var(--clr-brand-default);
}

.file-card {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    padding: 10px 8px;
    margin-bottom: 8px;
    background-color: #ffffff !important;
    border: 1px solid var(--clr-grey-default);
    border-radius: 8px;
}

.file-card-title {
    word-break: break-word;
}

.file-card-subtitle {
    color: var(--clr-grey-dark);
}

.file-card-type-icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--clr-grey-light);
    border-radius: 6px;
}

.file-card-close-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--clr-info-error-default);
}

.file-lock {
    display: flex;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    color: var(--clr-brand-default);
    cursor: pointer;
}
.document-preview-box {
    margin: 2.25rem 0 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
:root {
  --sidenav-width: 250px;
  --sidenav-collapse-width: 80px;
  --font-color: var(--clr-text-primary);
  --link-color: var(--clr-text-secondary);
  --link-active-border: var(--clr-brand-default);
}

/* TODO: Find a good way to do it */
body {
  background-color: #f7f8fa !important;
  color: var(--font-color);
}

.page-wrapper {
  display: flex;
  width: 100%;
}

.sidenav {
  min-width: var(--sidenav-width);
  max-width: var(--sidenav-width);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  /* z-index: 999; */
  background: #fff;
  color: var(--link-color);
  font-size: 16px;
  font-weight: 400;
  padding: 40px 0;
  transition: all 0.3s;
}

.content {
  width: calc(100% - var(--sidenav-width));
  padding: 40px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.sidenav .sidenav-brand {
  padding: 0 40px;
  max-width: 200px;
}

.sidenav-brand svg {
  width: 100%;
  height: 100%;
}

.sidenav-links {
  margin-top: 60px;
}
.sidenav-link:not(:last-child) {
  margin-bottom: 10px;
}
.sidenav-link a,
.sidenav-link button {
  width: 100%;
  color: inherit;
  padding: 10px 0;
  text-decoration: none !important;
  padding-left: 40px;
}

.sidenav-link a:hover,
.sidenav-link button:hover {
  background-color: var(--clr-brand-light);
  color: inherit;
}
.sidenav-link .link-active {
  border-right: solid 6px var(--link-active-border);
  color: var(--font-color);
  fill: var(--font-color);
  font-weight: 700;
}
.dropdown-toggle {
  position: relative;
}
.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.topbar {
  margin-bottom: 50px;
}
.topbar-left {
  font-family: inherit;
  font-size: 20px;
  font-weight: 700;
  color: var(--clr-brand-default);
}
.topbar-right {
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
}

.topbar-right .topbar-username {
  margin-right: 20px;
}
@media (max-width: 768px) {
  .sidenav {
    min-width: var(--sidenav-collapse-width);
    max-width: var(--sidenav-collapse-width);
    text-align: center;
  }
  .content {
    width: calc(100% - 85px);
    padding: 40px 10px;
  }
  .sidenav .sidenav-brand {
    padding: 0 20px;
  }
  .sidenav-link a, .sidenav-link button {
    padding-left: 30px;
  }
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.signin-buttons--container{
    display: flex;
    margin-left: auto;
    margin-right: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}   

.text__underline{
    text-decoration: underline;
}

.enterprise-title {
  font-size: 20px;
}

.enterprise-page-subtitle {
  font-size: 16px;
  font-weight: 700;
}

.text-thin {
  font-weight: 300;
}

:root {
  --primary: #003D80;
  --secondary: #0057B7;
  --lightbg: #FDFDFD;
  --textgray: #898989;
  --max-width: 100vw;
  --white: #ffffff;
  --lightSky: #F1FAFF;
  --lightgray: #9e9e9e;
  --placeholderColor: #8B8B8B;
  --lightBlueBg: #F5F5FC;
  --lightGreen: #ECFFF1;
  --green: #43B96F;
  --lightDanger: #FFF5F2;
  --danger: #DA7C2A;

  /* Branding Colours */
  --clr-brand-default: #0057B7;
  --clr-brand-light: #E5EEF8;
  --clr-brand-dark: #003D80;

  --clr-text-primary: #15171A;
  --clr-text-secondary: #696D70;
  --clr-text-tertiary: #999EA3;

  --clr-grey-default: #C9CDD1;
  --clr-grey-light: #F5F7FA;
  --clr-grey-dark: #B8BBBF;

  --clr-pure-white: #FFFFFF;

  --clr-info-error-default: #D13B3B;
  --clr-info-error-light: #F8E2E2;
  --clr-info-error-dark: #781313;

  --clr-info-success-default: #2C9E82;
  --clr-info-success-light: #DCF5EF;
  --clr-info-success-dark: #0C7057;

  --clr-info-alert-default: #EC9527;
  --clr-info-alert-light: #FCF2E3;
  --clr-info-alert-dark: #8A5006;

  --clr-info-highlight-default: #F5C245;
  --clr-info-highlight-light: #FCEECA;
  --clr-info-highlight-dark: #AC7D08;
}

* {
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
}

.heading {
  font-size: 30px;
  color: var(--primary);
  font-weight: 700;
}
.subheading {
  color: var(--primary);
  font-weight: 500;
}

.w-50 {
  width: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  background-color: var(--secondary);
  padding: 8px 35px;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  transition: 0.5s;
}

.btn-primary:hover {
  background-color: var(--secondary);
}

.btn-translucent {
  background-color: var(--lightSky);
  color: var(--secondary);
  font-size: 10px;
  font-weight: 700;
}

.btn-pending {
  background-color: var(--lightSky);
  color: var(--secondary);
  font-size: 10px;
  padding: 5px 20px;
}

.btn-verified {
  background-color: var(--lightGreen);
  color: var(--green);
  font-size: 10px;
  padding: 5px 20px;
}

.btn-rejected {
  background-color: var(--lightDanger);
  color: var(--danger);
  font-size: 10px;
  padding: 5px 20px;
}

.table-wrapper {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  background: white;
  border-radius: 8px;
  padding: 50px 50px 25px 50px;
}

.lightLabel {
  color: var(--textgray);
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.no-text-decoration {
  text-decoration: none !important;
}

.grey-text {
  color: var(--textgray);
}

.primarycolor {
  color: var(--secondary) !important;
}

.secondary-color {
  color: var(--primary) !important;
}

.word-break {
  word-break: break-word;
}

.btn1 {
  background-color: var(--primary);
  color: white;
}

.btn2 {
  background-color: var(--secondary);
  color: white;
  height: 48px;
  border-radius: 4px;
}

.btn2:hover {
  color: var(--white);
}

.input-login {
  background-color: var(--lightbg) !important;
  border: #e4e5e7 !important;
  font-size: small;
}

.only-icon {
  padding: 8px 16px !important;
}

.search-input {
  width: 40%;
  text-align: center;
  background-color: var(--lightbg);
  border: 1px solid #e6e6e6;
}

.search-input::placeholder {
  color: var(--placeholderColor);
  font-size: 14px;
}

.search-input-user {
  width: 300px;
}

.input-login:-webkit-autofill,
.input-login:-webkit-autofill:hover,
.input-login:-webkit-autofill:focus,
.input-login:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--primary);
}

.font-weight-bold {
  font-weight: bold;
}
.font-size-small {
  font-size: small;
}
.color-white {
  color: #ffffff;
}
.border-dash {
  border-style: dashed;
}
.display-none {
  display: none;
}
.white-bg {
  background-color: #ffffff !important;
}
.tnc-font {
  font-size: 9px;
}
.cursor-pointer {
  cursor: pointer;
}

.primary-color {
  color: var(--primary);
}
.secondary-color {
  color: var(--secondary);
}

.table-input-box {
  border-radius: 20px;
  border: 1px solid var(--primary);
  max-width: 220px;
  font-size: 14px;
  margin-left: 42px;
  padding: 5px;
  height: 38px;
  text-align: center;
  color: var(--primary);
  font-weight: 700;
}

/* form input css start */
.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-signin-label-group {
  position: relative;
  margin-bottom: 10px;
}

.form-control:focus {
  border: 1px solid var(--textgray);
  box-shadow: none;
}

.form-signin-label-group > input,
.form-signin-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: #58585829;
}

.form-signin-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: var(--textgray);
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-signin-label-group input::placeholder {
  color: transparent;
}

.form-signin-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-signin-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 10.6px;
  color: var(--textgray) !important;
}

.form-group-white-bg {
  background-color: #ffffff !important;
  border-bottom: 1px solid #d6d6d6;
}
.form-group-white-bg > label {
  background-color: transparent !important;
}

.search-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}

/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-signin-label-group > label {
    display: none;
  }
  .form-signin-label-group input::-ms-input-placeholder {
    color: var(--primary) !important;
  }
}

/* Fallback for IE
  -------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-signin-label-group > label {
    display: none;
  }
  .form-signin-label-group input:-ms-input-placeholder {
    color: var(--primary);
  }
}

/* form input css closed */

@media only screen and (max-width: 998px) {
  .search-input-user {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .table-wrapper {
    padding: 20px 15px;
    border-radius: 0px;
  }

  .pencil-button {
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 100;
  }

  .search-input {
    width: 100%;
  }
  .heading {
    font-size: 20px;
  }

  .w-md-100 {
    width: 100%;
  }
}

button svg {
  margin-right: 5px;
}

.rf-btn-group button:not(:last-child) {
  margin-right: 20px;
}

.dashboard-container {
  background-color: var(--white);
  padding: 40px;
  border-radius: 8px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    padding: 20px;
  }
}


.auth-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lightBlueBg);
  }
  
  .auth-card {
    width: 35%;
    border-radius: 10px;
    box-shadow: 0px 0px 15px whitesmoke;
    padding: 60px 85px 40px 90px;
    background-color: #ffffff;
  }
  
  .logo-login {
    width: 140px;
  }
  
  @media only screen and (max-width: 1024px) {
    .auth-card {
      width: 60%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .auth-card {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 15px gainsboro;
      padding: 40px 20px;
      margin: 0px 20px;
    }
  }
  
