.multi-select__container {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}

.multi-select__label {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}

.multi-select__box {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  padding: 4px;
  min-height: 52px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multi-select__box.active {
  box-shadow: 0px 0px 0px 1px grey;
}

.multi-select__box--placeholder {
  margin-left: 5px;
  opacity: 0.7;
  user-select: none;
}

.multi-select__box-badge__container {
  display: flex;
  flex-wrap: wrap;
}

.multi-select__box-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  margin: 3px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.multi-select__box-badge--close-btn {
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.multi-select__box__action-btn-group {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.multi-select__box__action-indicator {
  color: #ced1da;
}

.multi-select__box__action-btn {
  padding: 5px;
  cursor: pointer;
  opacity: 0.6;
}
.multi-select__box__action-btn:hover {
  opacity: 1;
}

.multi-select__option-list-container {
  display: none;
  position: absolute;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 10;
}

.multi-select__option-list-container.active {
  display: block;
}

.multi-select__option-list {
  display: block;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 250px;
  overflow-y: auto;
  list-style: none;
}

.multi-select__option-list-item {
  padding: 0 15px;
}

.multi-select__option-list-item--group-title {
  opacity: 0.7;
  text-transform: uppercase;
  padding: 10px;
  user-select: none;
}

.multi-select__option-list-item--checkbox-label {
  margin: 0;
  padding: 10px;
  width: 100%;
  cursor: pointer;

  display: flex;
  align-items: center;
}

.multi-select__option-list-item--checkbox-label-text {
  margin-left: 10px;
}
