.rf-select {
    --text-color: var(--clr-text-primary);
    --bg-color: var(--clr-pure-white);
    --border-color: var(--clr-grey-light);
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    margin: 0;
    width: 100%;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.rf-label {
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
}

.rf-range {
    width: 100%;
}

.rf-range-input {
    --text-color: var(--clr-primary-default);
    --bg-color: #FCFCFC;
    --border-color: #DBDBDB;
    max-width: 120px;
    padding: 5px 10px;
    width: 100%;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    text-align: center;
}

.rf-range-val {
    --text-color: var(--clr-text-primary);
    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
    color: var(--text-color);
}

.rf-search {
    padding: 25px 10px;
    padding-right: 20px;
}

.rf-input-clear-btn {
    margin-left: -40px; 
    z-index: 100;
}

.rf-input-star {
    color: red;
}

.rf-form-msg {
    font-size: 12px;
    font-weight: 400;
    margin: 5px 0;
}

.form-group:last-child {
    margin-bottom: 0;
}

.search-box__label {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
}