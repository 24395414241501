.rf-pagination {
  margin: 10px 0;
}

.rf-pagination__relative-entry-count {
  margin: 0 10px;
}

.rf-pagination__list {
  list-style: none;
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;
}

.rf-pagination__item {
  user-select: none;
}

.rf-pagination__item:not(:last-child) {
  margin-right: 8px;
}

.rf-pagination__btn {
  background: none;
  padding: 5px 10px;
  color: var(--clr-brand-default);
  border: 1px solid transparent;
  border-radius: 6px;
  transition: border-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
}
.rf-pagination__btn:hover {
  border-color: var(--clr-brand-default);
  outline: 0;
  transition-duration: 0.1s;
}
.rf-pagination__btn.active {
  color: var(--clr-pure-white);
  background-color: var(--clr-brand-default);
  border-color: transparent;
  pointer-events: none;
}

.rf-pagination__btn--action {
  background: none;
  max-width: 32px;
  padding: 5px 10px;
  color: var(--clr-brand-default);
  border: none;
}

.rf-pagination__btn--action:disabled {
  color: #4d4f5c;
  opacity: 0.6;
}

.rf-pagination__jump-to {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-left: 20px;
}

.rf-pagination__jump-to--text {
  margin-right: 10px;
}

.rf-pagination__jump-to--input {
  width: 50px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.rf-pagination__per-page-selector {
  background: transparent;
  border: none;
  padding: 0;
}
