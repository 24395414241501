.rf-nav-tab-wrapper {
    background-color: var(--clr-pure-white);
    margin-bottom: 42px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.rf-nav-tab {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    color: var(--clr-text-secondary);
    font-weight: 500;
}

.rf-nav-tab-item {
    flex: 1 1 auto;
    text-align: center;
}

.rf-nav-tab-link {
    color: var(--clr-text-secondary);
    text-decoration: none;
    padding: 16px 0;
}

.rf-nav-tab-link:hover {
    text-decoration: none;
}

.rf-nav-tab-link.active {
    font-weight: 600;
    color: var(--clr-brand-default);
    border-bottom: 2px solid var(--clr-brand-default);
}