.rf-badge {
    --badge-success: var(--clr-info-success-default);
    --badge-success-bg: var(--clr-info-success-light);
    --badge-pending: var(--clr-info-alert-default);
    --badge-pending-bg: var(--clr-info-alert-light);
    --badge-reject: var(--clr-info-error-default);
    --badge-reject-bg: var(--clr-info-error-light);

    width: 100%;
    text-align: center;
    font-family: inherit;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
}

.rf-badge.capitalize {
    text-transform: capitalize;
}

.rf-badge-success {
    color: var(--badge-success);
    background-color: var(--badge-success-bg);
}
.rf-badge-pending {
    color: var(--badge-pending);
    background-color: var(--badge-pending-bg);
}
.rf-badge-reject {
    color: var(--badge-reject);
    background-color: var(--badge-reject-bg);
}