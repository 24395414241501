
.auth-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lightBlueBg);
  }
  
  .auth-card {
    width: 35%;
    border-radius: 10px;
    box-shadow: 0px 0px 15px whitesmoke;
    padding: 60px 85px 40px 90px;
    background-color: #ffffff;
  }
  
  .logo-login {
    width: 140px;
  }
  
  @media only screen and (max-width: 1024px) {
    .auth-card {
      width: 60%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .auth-card {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 15px gainsboro;
      padding: 40px 20px;
      margin: 0px 20px;
    }
  }
  